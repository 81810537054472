import * as React from "react";

import { getWizardStepName } from "@bokio/bank/src/utils/bokioBusinessAccountUtils";
import { AnalyticsEventCategory, useMetric } from "@bokio/hooks/useMetric/useMetric";
import { BankLangFactory, GeneralLangFactory } from "@bokio/lang";
import {
	canContinueBbaOnboarding,
	getWizardStep,
} from "@bokio/mobile-web-shared/areas/bank/utils/bokioBusinessAccountUtils";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { mergeClassNames } from "@bokio/utils/classes";

import { BokioPlanBokioBusinessAccountButton } from "../BokioPlanFeatureButton/BokioPlanBokioBusinessAccountButton";
import { PricePlanFeature } from "../BokioPlanLockBadge/BokioPlanLockBadge";

import type { UseBokioBusinessAccountOnboardingModalReturn } from "@bokio/settings/src/scenes/BokioPricePlans/components/StartBokioBusinessAccountOnboardingModal/StartBokioBusinessAccountOnboardingModal";

import * as styles from "./bbaOnboardingPopoverFooter.scss";

import OnboardingState = m.Bokio.Bank.Contract.Mir.Onboarding.OnboardingState;
type OnboardingStatusViewModel = m.Bokio.Bank.Contract.Mir.Onboarding.OnboardingStatusViewModel;
interface BbaOnboardingPopoverFooterProps {
	variant: "modal" | "popover";
	onboardingStatus: OnboardingStatusViewModel;
	onClose: () => void;
	bokioBusinessAccountModalHook: UseBokioBusinessAccountOnboardingModalReturn;
}

export const BbaOnboardingPopoverFooter: React.FC<BbaOnboardingPopoverFooterProps> = ({
	variant,
	onboardingStatus,
	bokioBusinessAccountModalHook,
}) => {
	const generalLang = GeneralLangFactory();
	const bankLang = BankLangFactory();
	const { bbaOnboardingModal } = bokioBusinessAccountModalHook;
	const [addMetric] = useMetric();

	const shouldShowContinueOnboarding = canContinueBbaOnboarding(onboardingStatus.State);

	const getCtaInfoCaption = () => {
		return onboardingStatus.State === OnboardingState.WaitingForAuth ||
			onboardingStatus.State === OnboardingState.WaitingInQueue
			? generalLang.Status
			: generalLang.NextStep;
	};

	const getCtaInfoMainText = () => {
		return onboardingStatus.State === OnboardingState.WaitingForAuth
			? bankLang.ReadyToBeActivated
			: onboardingStatus.State === OnboardingState.WaitingInQueue
				? bankLang.PreparingYourAccount
				: getWizardStepName(getWizardStep(onboardingStatus.State));
	};

	return (
		<div className={mergeClassNames(styles.root, variant === "popover" && styles.rootInPopover)}>
			<p className={styles.ctaInformation}>
				<span className={styles.ctaInformationCaption}>{getCtaInfoCaption()}</span>
				<span className={styles.ctaInformationMainText}>{getCtaInfoMainText()}</span>
			</p>
			<div className={styles.ctaButtonWrapper}>
				{
					// return a Svea component for continuing onboarding instead of null when we show BBA onboarding in the popover
					shouldShowContinueOnboarding ? null : (
						<BokioPlanBokioBusinessAccountButton
							feature={PricePlanFeature.StartBokioBusinessAccountOnboarding}
							type="button"
							onClick={() => {
								bbaOnboardingModal.show();
								addMetric(AnalyticsEventCategory.Bba, "BbaOnboardingPopover_StartOnboarding");
							}}
						>
							{bankLang.ActivateYourAccount}
						</BokioPlanBokioBusinessAccountButton>
					)
				}
			</div>
		</div>
	);
};
