import { flattenedCssVariables } from "./_theme.flattened.generated";

import type { MarginAway } from "../components/TypographicElements/TypographicElements.types";
import type { CssVariableColorNames, StandardSpacingNames } from "./theme";

export { theme } from "./theme";

/** Creates a CSS variable to allow color customization from React. */
export const cssVariableForColor = (
	color: CssVariableColorNames | undefined,
	variableName: string,
	mergeWith?: React.CSSProperties,
): React.CSSProperties | undefined => {
	if (!color) {
		return mergeWith;
	}

	const target = mergeWith || {};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	target[variableName as any] = flattenedCssVariables["color-" + color];

	return target;
};

/** Creates a CSS variable to allow color customization from React. */
export const cssVariableForBgColor = (
	backgroundColor: CssVariableColorNames | undefined,
	variableName: string,
	mergeWith?: React.CSSProperties,
): React.CSSProperties | undefined => {
	if (!backgroundColor) {
		return mergeWith;
	}

	const target = mergeWith || {};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	target[variableName as any] = flattenedCssVariables["color-" + backgroundColor];

	return target;
};

export const cssVariableForSpacing = (
	spacing: StandardSpacingNames | undefined,
	variableName: string,
	mergeWith?: React.CSSProperties,
): React.CSSProperties | undefined => {
	if (!spacing) {
		return mergeWith;
	}

	const target = mergeWith || {};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	target[variableName as any] = flattenedCssVariables["spacing-" + spacing];

	return target;
};

export const cssVariableForMargin = (
	margin: MarginAway | undefined,
	variableName: string,
	mergeWith?: React.CSSProperties,
): React.CSSProperties | undefined => {
	if (!margin) {
		return mergeWith;
	}

	const target = mergeWith || {};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	target[variableName as any] = flattenedCssVariables["spacing-" + margin];

	return target;
};
